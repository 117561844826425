// https://supabase.com/docs/guides/auth/auth-helpers/nextjs-server-components#creating-a-supabase-client
import { createPagesBrowserClient } from '@supabase/auth-helpers-nextjs';
import { Database } from '@/lib/database.types';
import { DEV_PORT, ROOT_DOMAIN } from '@/constants';

// console.log({
//   domain: ROOT_DOMAIN,
//   maxAge: '100000000',
//   path: '/',
//   sameSite: 'Lax',
//   secure: process.env.NODE_ENV,
// });

export default createPagesBrowserClient<Database>({
  cookieOptions: {
    // domain: `demo.${ROOT_DOMAIN.replace(`:${DEV_PORT}`, '')}`, // Disabled so that we can log into the demo subdomain without affecting the root domain
    // maxAge: 100000000,
    path: '/',
    sameSite: 'lax',
    secure: process.env.NODE_ENV == 'development' ? false : true,
  },
});
