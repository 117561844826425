import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  /* Account */
  createAccount,
  getAccountById,
  getAllAccountsForUser,

  /* Updates */
  getAllUpdatesForAccount,
  getLast30DaysUpdatesForAccount,
  
  /* Activities */
  getAllActivitiesForAccount,

  /* Target */
  getAllTargetsForAccount,
  addTargetForAccount,
  removeTargetForAccount,

  //TODO: implement all models
  /* Conversion */

  /* Organization */
  createOrganization,
  getActiveProductsWithPrices,
  getAllOrganizationsForUser,
  getPendingTeamInvitationsInOrganization,
  getOrganizationById,
  getOrganizationSubscription,
  getTeamMembersInOrganization,
  updateOrganizationTitle,

  /* User & Auth */
  getUserProfile,
  getUserOrganizationRole,
  resetPassword,
  signInWithMagicLink,
  signInWithPassword,
  signInWithProvider,
  signUp,
  updatePassword,
  updateUserProfileNameAndAvatar,

  /* Admin */
  getIsAppInMaintenanceMode,
  updateAccount,
  updateUserProfile,
} from './supabase-queries';
import supabaseClient from './supabase-browser';
import { AuthProvider, Table, UnwrapPromise } from '@/types';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { useLoggedInUser } from '@/hooks/useLoggedInUser';
import { uploadPublicUserAvatar } from './supabase-storage-queries';
import { useRef } from 'react';
import { Instagram } from '@shared-libs/instagram';
import { DEMO_ACCOUNT_IDS, DEMO_USER_EMAIL } from '@/constants';

// update organization title mutation

export const useUpdateOrganizationTitleMutation = ({
  onSuccess,
  onError,
  organizationId,
}: {
  onSuccess?: () => void;
  onError?: (error: unknown) => void;
  organizationId: string;
}) => {
  const queryClient = useQueryClient();
  const toastRef = useRef<string | null>(null);
  return useMutation(
    async ({ title }: { title: string }) => {
      return updateOrganizationTitle(supabaseClient, organizationId, title);
    },
    {
      onMutate: () => {
        const toastId = toast.loading('Updating organization title...');
        toastRef.current = toastId;
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['organization', organizationId]);
        toast.success('Organization title updated', {
          id: toastRef.current || '',
        });
        toastRef.current = null;
        onSuccess?.();
      },
      onError: (error) => {
        onError?.(error);
        toast.error('Failed to update organization title', {
          id: toastRef.current || '',
        });
        toastRef.current = null;
      },
    }
  );
};

/* ==================== */
/* USER PROFILE */
/* ==================== */

export const useUserProfile = (initialData?: Table<'user_profiles'>) => {
  const user = useLoggedInUser();
  console.log('🚀 ~ file: react-query-hooks.ts:101 ~ user', user)
  return useQuery<Table<'user_profiles'>>(
    ['user-profile', user?.id],
    async () => {
      if (user) {
        const userProfile = await getUserProfile(supabaseClient, user.id);
        if (!userProfile) {
          throw new Error('Failed to fetch user profile for User ID: ' + user.id);
        }
        return userProfile;
      }
      throw new Error('User is not logged in');
    },
    {
      initialData,
    }
  );
};

export const useUpdateUserProfile = ({
  onSuccess,
  onError,
  onMutate,
}: {
  onSuccess?: () => void;
  onError?: (error: unknown) => void;
  onMutate?: () => void;
}) => {
  const user = useLoggedInUser();
  const queryClient = useQueryClient();
  // const toastRef = useRef<string | null>(null);
  
  return useMutation(
    async (data: { avatar_url?: string; full_name?: string, traffic_source?: string, enabled?: boolean, customer_id?: string; email?: string; user_info?: any }) => {
      if (user && user.email === DEMO_USER_EMAIL)
        return true // If the user is the demo user, reject the mutation
      return updateUserProfile(supabaseClient, user.id, data);
    },
    {
      onMutate: () => {
        // toastRef.current = toast.loading('Updating profile...');
        onMutate?.();
      },
      onSuccess: () => {
        // toast.success('Profile updated', {
        //   id: toastRef.current || '',
        // });
        queryClient.invalidateQueries(['user-profile', user.id]);
        onSuccess?.();
      },
      onError: (error) => {
        // toast.error('Failed to update profile', {
        //   id: toastRef.current || '',
        // });
        onError?.(error);
      },
    }
  );
};


export const useUpdateUserFullnameAndAvatarMutation = ({
  onSuccess,
  onError,
  onMutate,
}: {
  onSuccess?: () => void;
  onError?: (error: unknown) => void;
  onMutate?: () => void;
}) => {
  const user = useLoggedInUser();
  const queryClient = useQueryClient();
  const toastRef = useRef<string | null>(null);
  
  return useMutation(
    async (data: { avatarUrl?: string; fullName?: string }) => {
      if (user && user.email === DEMO_USER_EMAIL)
        return true // If the user is the demo user, reject the mutation
    
      return updateUserProfileNameAndAvatar(supabaseClient, user.id, data);
    },
    {
      onMutate: () => {
        toastRef.current = toast.loading('Updating profile...');
        onMutate?.();
      },
      onSuccess: () => {
        toast.success('Profile updated', {
          id: toastRef.current || '',
        });
        queryClient.invalidateQueries(['user-profile', user.id]);
        onSuccess?.();
      },
      onError: (error) => {
        toast.error('Failed to update profile', {
          id: toastRef.current || '',
        });
        onError?.(error);
      },
    }
  );
};

export const useUploadUserAvatarMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (avatarUrlPath: string) => void;
  onError?: (error: unknown) => void;
}) => {
  const user = useLoggedInUser();
  const toastRef = useRef<string | null>(null);
  return useMutation(
    async (file: File) =>
      await uploadPublicUserAvatar(supabaseClient, user.id, file, file.name, false, {
        upsert: true,
      }),
    {
      onMutate: () => {
        toastRef.current = toast.loading('Uploading avatar...');
      },
      onSuccess: (avatarUrl: string) => {
        onSuccess?.(avatarUrl);
        toast.success('Avatar uploaded', {
          id: toastRef.current || '',
        });
      },
      onError: (error) => {
        onError?.(error);
        toast.error('Failed to upload avatar', {
          id: toastRef.current || '',
        });
      },
    }
  );
};

/* ==================== */
/* Accounts             */
/* ==================== */

export type InitialAccountListType = UnwrapPromise<
  ReturnType<typeof getAllAccountsForUser>
>;

export const useAccountsList = (initialAccountList: InitialAccountListType) => {
  const user = useLoggedInUser();
  return useQuery<InitialAccountListType>(
    //TODO: fix next line?
    ['account-list', user?.id],
    async () => {
      return getAllAccountsForUser(supabaseClient);
    },
    {
      // placeholderData: [],
      initialData: initialAccountList,
    }
  );
};

export const useCreateAccountMutation = ({
  onSuccess,
  onSettled,
  onError,
}: {
  onSuccess?: (accountId?: string) => void;
  onSettled?: () => void;
  onError?: (error: Error) => void;
}) => {
  const user = useLoggedInUser();
  const queryClient = useQueryClient();
  const toastRef = useRef<string>();
  return useMutation(
    async ({ instagramUsername, themeColor }: { instagramUsername: string; themeColor: string }) => {
      const instagram_profile_data = await Instagram.fetchInstagramProfile(instagramUsername);
      if (themeColor)
        instagram_profile_data.themeColor = themeColor;
      
      console.log('🚀 ~ file: react-query-hooks.ts:219 ~ instagram_profile_data', instagram_profile_data);
      console.log(instagram_profile_data);
      if (!instagram_profile_data) 
        throw new Error(String('This Instagram account cannot be connected. Please double-check the username.'));
      
      // Download Instagram profile picture and upload to Supabase Storage in background
      try {
        fetch(`/api/instagram/download-avatar?username=${instagramUsername}`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ 
            username: instagramUsername,
            avatar_url: instagram_profile_data.graphql.user.profile_pic_url 
          }),
        });
      } catch (error) {
        console.error('Error downloading Instagram avatar: ', error);
      }

      return createAccount(supabaseClient, user, instagram_profile_data);
    },
    {
      onMutate: async (instagramUsername) => {
        console.log(instagramUsername);
        toastRef.current = toast.loading(`Connecting account...`);
      },
      onSuccess: (data) => {
        // Invalidate the account list query
        //TODO: fix next line?
        queryClient.invalidateQueries(['account-list', user?.id]);
        toast.success(`Account connected successfully!`, {
          id: toastRef.current || '',
        });
        toastRef.current = undefined;
        onSuccess?.(data.id);
      },
      onSettled,
      onError: (error: Error) => {
        toast.dismiss()
        console.error('Error creating account: ', error.message);
        if (error.message.includes('This Instagram profile is already connected to an Keesy account.')) {
          toast.error('This Instagram profile is already connected to an Keesy account.', {
            id: toastRef.current || '',
          });
        }else {
          toast.error('An error occurred while connecting the account.', {
            id: toastRef.current || '',
          });
        }
        onError?.(error);
      },
    }
  );
};

export type AccountByIdData = UnwrapPromise<ReturnType<typeof getAccountById>>;

export const useGetAccountById = (
  accountId: string,
  initialAccountData?: AccountByIdData
) => {
  return useQuery(
    ['account', accountId],
    async () => {
      return getAccountById(supabaseClient, accountId);
    },
    {
      initialData: initialAccountData,
    }
  );
};

export const useGetLastAddedAccountForUser = () =>
  // initialAccountData?: AccountByIdData
  {
    const user = useLoggedInUser();
    return useQuery(
      ['last-added-account', user.id],
      async () => {
        // fetch the last added account for the user by created_at
        const { data, error } = await supabaseClient
          .from('accounts')
          .select('*')
          .eq('user_id', user.id)
          .order('created_at', { ascending: false })
          .limit(1)
          
          if (data && data.length > 0) {
            return data[0]; // return the first account if exists
          } else {
            return null; // or return null, or throw an error, or whatever you prefer
          }
      },
      {
        // initialData: initialAccountData,
      }
    );
  };

export const useGetAllAccountsForUser = () => {
  const user = useLoggedInUser();
  return useQuery(
    ['all-accounts', user.id],
    async () => {
      // fetch the last added account for the user by created_at
      const { data, error } = await supabaseClient
        .from('accounts')
        .select('*')
        .eq('user_id', user.id);
      console.log('All accounts: ', data);
      return data;
    },
    {
      // initialData: initialAccountData,
    }
  );
};

export const useUpdateAccountMutation = ({
  onSuccess,
  onError,
  accountId,
  hideToast,
}: {
  onSuccess?: (data: Table<'accounts'>) => void;
  onError?: (error: unknown) => void;
  accountId: string;
  hideToast?: boolean;
}) => {
  const queryClient = useQueryClient();
  const toastId = 'update-account-settings'; // Reuse the same toast to prevent multiple toasts for updating account action
  // const toastRef = useRef<string | null>(null);
  return useMutation(
    async ({ ...updatedData }: Partial<Table<'accounts'>>) => {
      // If the account is a demo account, reject the mutation
      if (DEMO_ACCOUNT_IDS.includes(accountId)) {
        toast.loading('Updating account settings...', { id: toastId });
        // Sleep for 0.3 seconds
        await new Promise((resolve) => setTimeout(resolve, 300));
        toast.success('Account settings updated successfully!', { id: toastId });
        return Promise.resolve();
      }
      return updateAccount(supabaseClient, accountId, updatedData);
    },
    {
      onMutate: () => {
        if (!hideToast) {
          toast.loading('Updating account settings...', { id: toastId });
        }
      },
      onSuccess: (data: Table<'accounts'>) => {
        queryClient.invalidateQueries(['account', accountId]);
        if (!hideToast) {
          toast.success('Account settings updated successfully!', { id: toastId });
        }
        onSuccess?.(data); // Pass the data to the onSuccess callback
      },
      onError: (error) => {
        onError?.(error);
        if (!hideToast) {
          toast.error('Failed to update account settings', { id: toastId });
        }
      },
    }
  );
};

export type UpdatesByIdData = UnwrapPromise<ReturnType<typeof getAllUpdatesForAccount>>;
export const useGetAllUpdatesForAccount = (
  accountId: string,
  initialData?: UpdatesByIdData
) => {
  // console.log("🚀 ~ file: react-query-hooks.ts:380 ~ accountId", accountId)
  return useQuery(
    ['updates', accountId],
    async () => {
      return getAllUpdatesForAccount(supabaseClient, accountId);
    }
    // {
    //   initialData: initialData,
    // }
  );
};


/* ==================== */
/* Activities */
/* ==================== */

export type ActivitiesByIdData = UnwrapPromise<ReturnType<typeof useGetAllActivitiesForAccount>>;
export const useGetAllActivitiesForAccount = (
  accountId: string,
  page?: number,
  initialData?: AccountByIdData
) => {
  // console.log("🚀 ~ file: react-query-hooks.ts:402 ~ accountId", accountId)
  return useQuery(
    ['activities', accountId],
    async () => {
      return getAllActivitiesForAccount(supabaseClient, accountId, page);
    }
    // {
    //   initialData: initialData,
    // }
  );
};


/* ==================== */
/* Targets */
/* ==================== */

export type TargetsByIdData = UnwrapPromise<ReturnType<typeof useGetAllTargetsForAccount>>;
export const useGetAllTargetsForAccount = (accountId: string | null) => {
  return useQuery(
    ['targets', accountId],
    async () => {
      console.log("🚀 ~ file: react-query-hooks.ts:useGetAllTargetsForAccount() ~ accountId", accountId)
      if (accountId) {
        return getAllTargetsForAccount(supabaseClient, accountId);
      } else {
        return [];
      }
    },
    { }
  );
};

export const useAddTargetMutation = ({
  onMutate,
  onSuccess,
  onError,
}: {
  onMutate?: (target: Partial<Table<'targets'>>) => void;
  onSuccess?: (data: Table<'targets'>) => void;
  onError?: (error: unknown, variables?: { accountId: string; target: Partial<Table<'targets'>> }, context?: unknown) => void;
}) => {
  const queryClient = useQueryClient();
  const toastRef = useRef<string>();
  return useMutation(
    async ({ accountId, target }: { accountId: string; target: Partial<Table<'targets'>> }) => {

      // Optimistically return the new target immediately
      const newTarget = { 
        ...target, 
        id: target.id || Math.floor(Math.random() * 1000000),
        account_id: accountId, 
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
        type: target.type || 'user',
        name: target.name || '',
        enabled: target.enabled || true,
        instagram_id: target.instagram_id || '',
        thumbnail: target.thumbnail || '',
        count: target.count || 0,
      };
     
      // Run the actual mutation in the background
      return addTargetForAccount(supabaseClient, accountId, target)
      .then(() => newTarget);
    },
    {
      onMutate: (target) => {
        toastRef.current = toast.loading(`Adding target ${target.target.name}...`, { id: toastRef.current || '' })
        onMutate?.(target.target);
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries(['targets', data.account_id]);
        toast.success(`Hooray! The target ${data.name} has been added successfully.`, { id: toastRef.current || '' });
        onSuccess?.(data);
      },
      onError: (error, variables, context) => {
        console.log('error in useAddTargetMutation: ', error);
        if ((error as any).code && (error as any).code === '23505')
          toast.error(`The ${variables.target.name} target already exists for your account.`, { id: toastRef.current || '' });
        else 
          toast.error('An error occurred while adding the target.', { id: toastRef.current || '' });  
        toastRef.current = undefined;
        onError?.(error, variables, context);
      },
    }
  );
};

export const useRemoveTargetMutation = ({
  onMutate,
  onSuccess,
  onError,
}: {
  onMutate?: (targetId: string) => void;
  onSuccess?: (targetId: string) => void;
  onError?: (error: unknown, variables?: { accountId: string; targetId: string }, context?: unknown) => void;
}) => {
  const queryClient = useQueryClient();
  const toastRef = useRef<string>();
  return useMutation(
    async ({ accountId, targetId }: { accountId: string; targetId: string }) => {
      return removeTargetForAccount(supabaseClient, accountId, targetId);
    },
    {
      onMutate: async (target) => {
        // Optimistically remove the target from the cache
        onMutate?.(target.targetId);
        await queryClient.cancelQueries(['targets', target.accountId]);
      },
      onError: (error, variables, context) => {
        toast.error('An error occurred while removing the target.', { id: toastRef.current || '' });
        toastRef.current = undefined;
        // If the mutation fails, roll back to the previous targets
        onError?.(error, variables, context);
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries(['targets', (data as any).account_id]);
        toast.success(`Target removed successfully!`, { id: toastRef.current || '' });
        onSuccess?.((data as any).id);
      },
      onSettled: () => {
        // After all mutation stages, refetch the targets
        queryClient.invalidateQueries(['targets']);
      },
    }
  );
};


/* ==================== */
/* Organizations */
/* ==================== */

export type InitialOrganizationListType = UnwrapPromise<
  ReturnType<typeof getAllOrganizationsForUser>
>;

export const useOrganizationsList = (
  initialOrganizationList: InitialOrganizationListType
) => {
  const user = useLoggedInUser();
  return useQuery<InitialOrganizationListType>(
    ['organization-list', user?.id],
    async () => {
      return getAllOrganizationsForUser(supabaseClient);
    },
    {
      initialData: initialOrganizationList,
    }
  );
};

export const useCreateOrganizationMutation = ({
  onSuccess,
  onSettled,
  onError,
}: {
  onSuccess?: (data: Table<'organizations'>) => void;
  onSettled?: () => void;
  onError?: (error: Error) => void;
}) => {
  const user = useLoggedInUser();
  const queryClient = useQueryClient();
  const toastRef = useRef<string>();
  return useMutation(
    async (name: string) => {
      return createOrganization(supabaseClient, user, name);
    },
    {
      onMutate: async (name) => {
        toastRef.current = toast.loading(`Creating organization ${name}...`);
      },
      onSuccess: (data) => {
        // Invalidate the organization list query
        queryClient.invalidateQueries(['organization-list', user?.id]);
        onSuccess?.(data);
        toast.success(`Organization ${data.title} created!`, {
          id: toastRef.current || '',
        });
        toastRef.current = undefined;
      },
      onSettled,
      onError: (error) => {
        const customError =
          error instanceof Error ? error : new Error(String(error));
        onError?.(customError);
        toast.error(`Error creating organization: ${customError.message}`, {
          id: toastRef.current || '',
        });
        toastRef.current = undefined;
      },
    }
  );
};

/* ==================== */
/* Organization */
/* ==================== */

export type OrganizationByIdData = UnwrapPromise<
  ReturnType<typeof getOrganizationById>
>;

export const useGetOrganizationById = (
  organizationId: string,
  initialOrganizationData?: OrganizationByIdData
) => {
  return useQuery(
    ['organization', organizationId],
    async () => {
      return getOrganizationById(supabaseClient, organizationId);
    },
    {
      initialData: initialOrganizationData,
    }
  );
};

export const useGetTeamMembersInOrganization = (organizationId: string) => {
  return useQuery(['team-members', organizationId], async () => {
    return getTeamMembersInOrganization(supabaseClient, organizationId);
  });
};

export const useGetTeamInvitationsInOrganization = (organizationId: string) => {
  return useQuery(['team-invitations', organizationId], async () => {
    return getPendingTeamInvitationsInOrganization(
      supabaseClient,
      organizationId
    );
  });
};

export const useInviteUserMutation = (
  organizationId: string,
  {
    onSuccess,
    onSettled,
    onError,
  }: {
    onSuccess?: () => void;
    onSettled?: () => void;
    onError?: (error: unknown) => void;
  }
) => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({
      email,
      organizationId,
    }: {
      email: string;
      organizationId: string;
    }) => {
      return axios.post(
        '/api/invitations/create',
        {
          email,
          organizationId,
        },
        {
          withCredentials: true,
        }
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['team-invitations', organizationId]);
        onSuccess?.();
      },
      onSettled: () => {
        onSettled?.();
      },
      onError: (error) => {
        onError?.(error);
      },
    }
  );
};

export const useGetOrganizationSubscription = (organizationId: string) => {
  return useQuery(
    ['organization-subscription', organizationId],
    async () => {
      return getOrganizationSubscription(supabaseClient, organizationId);
    },
    {
      retry: false,
      retryDelay: 0,
      refetchOnWindowFocus: false,
    }
  );
};

export const useGetAllActiveProducts = () => {
  return useQuery(
    ['all-active-products'],
    async () => {
      return getActiveProductsWithPrices(supabaseClient);
    },
    {
      refetchOnWindowFocus: false,
    }
  );
};

/* ==================== */
/* Subscription */
/*==================== */

export const useCreateOrganizationCheckoutSessionMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (url: string) => void;
  onError?: (error: unknown) => void;
}) => {
  return useMutation(
    async ({
      organizationId: organizationId,
      priceId,
    }: {
      organizationId: string;
      priceId: string;
    }) => {
      return axios.post<{
        sessionId: string;
      }>(
        `/api/stripe/${organizationId}/create-checkout-session`,
        {
          priceId,
        },
        {
          withCredentials: true,
        }
      );
    },
    {
      onSuccess: (axiosResponse) => {
        onSuccess?.(axiosResponse.data.sessionId);
      },
      onError: (error) => {
        onError!(error);
      },
    }
  );
};
export const useCreateOrganizationCustomerPortalMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (url: string) => void;
  onError?: (error: unknown) => void;
}) => {
  return useMutation(
    async ({ organizationId }: { organizationId: string }) => {
      return axios.post<{
        url: string;
      }>(
        `/api/stripe/${organizationId}/create-portal-link`,
        {},
        {
          withCredentials: true,
        }
      );
    },
    {
      onSuccess: (axiosResponse) => {
        onSuccess?.(axiosResponse.data.url);
      },
      onError: (error) => {
        onError!(error);
      },
    }
  );
};

export const useGetIsOrganizationAdmin = (organizationId: string) => {
  const user = useLoggedInUser();
  return useQuery(['isOrganizationAdmin', user.id], async () => {
    const memberInfo = await getUserOrganizationRole(
      supabaseClient,
      user.id,
      organizationId
    );
    return (
      memberInfo.member_role === 'admin' || memberInfo.member_role === 'owner'
    );
  });
};

/* ================= */
/* Update email */
/* ================= */

export function useUpdateUserEmailMutation() {
  const queryClient = useQueryClient();
  const toastRef = useRef<string | null>(null);
  return useMutation(
    async ({ oldEmail, newEmail }: { oldEmail: string; newEmail: string }) => {
      
      if (oldEmail === DEMO_USER_EMAIL)
        // If the user is the demo user, reject the mutation
        return true
    
      const { data } = await supabaseClient.auth.updateUser({
        email: newEmail,
      });
      return data;
    },
    {
      onMutate: async () => {
        toastRef.current = toast.loading('Updating email...');
      },
      onSuccess: () => {
        toast.success('Email updated!', {
          id: toastRef.current || '',
        });
        toastRef.current = null;
        queryClient.invalidateQueries(['user']);
        window.location.reload();
      },
      onError: (error) => {
        toast.error(String(error), {
          id: toastRef.current || '',
        });
        toastRef.current = null;
      },
    }
  );
}

export const useGetIsAppInMaintenanceMode = (initialData?: boolean) => {
  return useQuery<boolean>(
    ['getIsAppInMaintenanceMode'],
    async () => {
      return getIsAppInMaintenanceMode(supabaseClient);
    },
    {
      initialData,
      refetchOnWindowFocus: false,
    }
  );
};

/* ==================== */
/* AUTH */
/* ==================== */

export const useSignInWithMagicLink = ({
  onSuccess,
  onMutate,
  onError,
}: {
  onSuccess?: () => void;
  onMutate?: () => void;
  onError?: (error: unknown) => void;
}) => {
  const toastRef = useRef<string | null>(null);
  return useMutation(
    async ({ email }: { email: string }) => {
      return signInWithMagicLink(supabaseClient, email);
    },
    {
      onMutate: () => {
        toastRef.current = toast.loading('Sending magic link...');
        onMutate?.();
      },
      onSuccess: () => {
        toast.success('Check your email for the magic link!', {
          id: toastRef.current || '',
        });

        toastRef.current = null;
        onSuccess?.();
      },
      onError: (error) => {
        toast.error(String(error), {
          id: toastRef.current || '',
        });
        toastRef.current = null;
        onError?.(error);
      },
    }
  );
};

export const useSignInWithPassword = ({
  onSuccess,
  onMutate,
  onError,
}: {
  onSuccess?: () => void;
  onMutate?: () => void;
  onError?: (error: unknown) => void;
}) => {
  const toastRef = useRef<string | null>(null);
  return useMutation(
    async ({ email, password }: { email: string; password: string }) => {
      return signInWithPassword(supabaseClient, email, password);
    },
    {
      onMutate: () => {
        toastRef.current = toast.loading('Signing in...');
        onMutate?.();
      },
      onSuccess: () => {
        toast.success('Signed in!', {
          id: toastRef.current || '',
        });

        toastRef.current = null;
        onSuccess?.();
      },
      onError: (error) => {
        toast.error(String(error), {
          id: toastRef.current || '',
        });
        toastRef.current = null;
        onError?.(error);
      },
    }
  );
};

export const useResetPassword = ({
  onSuccess,
  onMutate,
  onError,
}: {
  onSuccess?: () => void;
  onMutate?: () => void;
  onError?: (error: unknown) => void;
}) => {
  const toastRef = useRef<string | null>(null);
  return useMutation(
    async ({ email }: { email: string }) => {
      return resetPassword(supabaseClient, email);
    },
    {
      onMutate: () => {
        toastRef.current = toast.loading('Sending password reset email...');
        onMutate?.();
      },
      onSuccess: () => {
        toast.success('Check your email for the password reset link!', {
          id: toastRef.current || '',
        });

        toastRef.current = null;
        onSuccess?.();
      },
      onError: (error) => {
        toast.error(String(error), {
          id: toastRef.current || '',
        });
        toastRef.current = null;
        onError?.(error);
      },
    }
  );
};

export const useUpdatePassword = ({
  onSuccess,
  onMutate,
  onError,
}: {
  onSuccess?: () => void;
  onMutate?: () => void;
  onError?: (error: unknown) => void;
}) => {
  const toastRef = useRef<string | null>(null);
  return useMutation(
    async ({ password }: { password: string }) => {
      return updatePassword(supabaseClient, password);
    },
    {
      onMutate: () => {
        toastRef.current = toast.loading('Updating password...');
        onMutate?.();
      },
      onSuccess: () => {
        toast.success('Password updated!', {
          id: toastRef.current || '',
        });

        toastRef.current = null;
        onSuccess?.();
      },
      onError: (error) => {
        toast.error(String(error), {
          id: toastRef.current || '',
        });
        toastRef.current = null;
        onError?.(error);
      },
    }
  );
};

export const useSignInWithProvider = () => {
  const toastRef = useRef<string | null>(null);
  return useMutation(
    async ({ provider }: { provider: AuthProvider }) => {
      return signInWithProvider(supabaseClient, provider);
    },
    {
      onMutate: ({ provider }) => {
        toastRef.current = toast.loading(`Signing in with ${provider}...`);
      },
    }
  );
};

export const useSignUp = ({
  onSuccess,
  onMutate,
  onError,
}: {
  onSuccess?: () => void;
  onMutate?: () => void;
  onError?: (error: unknown) => void;
}) => {
  const toastRef = useRef<string | null>(null);
  return useMutation(
    async ({ email, password }: { email: string; password: string }) => {
      return signUp(supabaseClient, email, password);
    },
    {
      onMutate: () => {
        toastRef.current = toast.loading('Signing up...');
        onMutate?.();
      },
      onSuccess: () => {
        toast.success('Signed up!', {
          id: toastRef.current || '',
        });

        toastRef.current = null;
        onSuccess?.();
      },
      onError: (error) => {
        toast.error(String(error), {
          id: toastRef.current || '',
        });
        toastRef.current = null;
        onError?.(error);
      },
    }
  );
};



